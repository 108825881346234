import InputAdornment from "@mui/material/InputAdornment"
import MatTextField, { TextFieldProps } from "@mui/material/TextField"

import isEmpty from "lodash/isEmpty"
import React, {
  FC,
  useEffect,
  useState,
  useRef,
  InputHTMLAttributes,
  ChangeEvent,
} from "react"

import isAlphanumeric from "validator/lib/isAlphanumeric"
import isEmail from "validator/lib/isEmail"
import isMobilePhone from "validator/lib/isMobilePhone"
import isNumeric from "validator/lib/isNumeric"
import isURL from "validator/lib/isURL"

import { create } from "src/helpers/bem"

import { TranslationMessages } from "src/translations"

import styles from "./InputField.module.scss"

const bem = create(styles, "InputField")

export type FieldType =
  | Extract<
      InputHTMLAttributes<unknown>["type"],
      "text" | "email" | "tel" | "number" | "password" | "url"
    >
  | "mobile"
  | "houseNumber"
  | "zip"

export type InputFieldProps = {
  type?: FieldType
  variant?: "standard" | "filled" | "outlined"
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  suffix?: string
  errorText?: string
  messages?: TranslationMessages
  onValidate?: (error?: string) => void
} & TextFieldProps

function checkPhoneNumber(val: string) {
  var num = val
  var mob = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g
  if (mob.test(num) == false) {
    val = ""
    return false
  }
  if (num.length > 15) {
    val = ""
    return false
  }

  return true
}

export const InputField: FC<InputFieldProps> = ({
  type = "text",
  variant = "outlined",
  id,
  min,
  max,
  minLength,
  maxLength,
  className,
  suffix,
  required,
  errorText,
  helperText,
  messages,
  value,
  onChange,
  onValidate,
  ...props
}) => {
  const [cursor, setCursor] = useState<{
    selectionStart: number | null
    selectionEnd: number | null
  } | null>(null)
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const input = ref.current
    if (input && cursor) {
      input?.setSelectionRange(cursor?.selectionStart, cursor?.selectionEnd)
    }
  }, [ref, cursor, value])

  const handleValidation = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = e?.target?.value
    const name = e?.target?.name
    if (isEmpty(e) || !onValidate) {
      return null
    }
    if (required && !value) {
      return onValidate(
        messages?.components?.common?.fields?.required ??
          "This field is required!",
      )
    }
    if (value) {
      switch (type) {
        case "number": {
          if (!isNumeric(value)) {
            return onValidate(
              messages?.components?.common?.fields?.inputField?.numeric ??
                "This is not a valid number!",
            )
          }
          const amount = parseFloat(value)
          if (name.includes('Amount') && (amount | 0) !== amount) {
            return onValidate(
              messages?.components?.common?.fields?.inputField?.numeric ??
                "This is not a valid number!",
            )
          }
          if (!!min && Number(value) < min) {
            return onValidate(
              `${messages?.components.common.fields.inputField.numericMin1} ${min} ${messages?.components.common.fields.inputField.numericMin2}`,
            )
          }
          if (!!max && Number(value) > max) {
            return onValidate(
              `${messages?.components.common.fields.inputField.numericMax1} ${max} ${messages?.components.common.fields.inputField.numericMax2}`,
            )
          }
          return onValidate(undefined)
        }
        case "email": {
          if (!isEmail(value)) {
            return onValidate(
              messages?.components?.common?.fields?.inputField?.email ??
                "This is not a valid email!",
            )
          }
          return onValidate(undefined)
        }
        case "zip": {
          // Trimlength checks if all chars are whitespaces
          if (value.length > 20 || value.trim().length === 0) {
            return onValidate(
              messages?.components?.common?.fields?.inputField?.zip ??
                "This is not a valid zip!",
            )
          }
          return onValidate(undefined)
        }
        case "houseNumber": {
          if (!isAlphanumeric(value)) {
            return onValidate(
              messages?.components?.common?.fields?.inputField?.houseNumber ??
                "This is not a valid house number!",
            )
          }
          return onValidate(undefined)
        }
        case "mobile": {
          if (!isMobilePhone(value)) {
            return onValidate(
              messages?.components?.common?.fields?.inputField?.tel ??
                "This is not a valid mobile number!",
            )
          }
          return onValidate(undefined)
        }
        case "tel": {
          if (!checkPhoneNumber(value?.replace(/\D/g, ""))) {
            return onValidate(
              messages?.components?.common?.fields?.inputField?.tel ??
                "This is not a valid phone number!",
            )
          }
          return onValidate(undefined)
        }
        case "password": {
          return
          // TODO: ADD A PASSWORD VALIDATION (WHICH ONE HAS THE XRM?)
          // onValidate(e, "This is not a valid password! The password has to be ...")
        }
        case "url": {
          if (!isURL(value)) {
            return onValidate(
              messages?.components?.common?.fields?.inputField?.url ??
                "This is not a valid url!",
            )
          }
          return onValidate(undefined)
        }
        default:
          return onValidate()
      }
    }
    return onValidate()
  }
  let inputProps = props?.InputProps ?? undefined
  if (suffix) {
    inputProps = {
      ...props?.InputProps,
      endAdornment: (
        <InputAdornment className={bem("suffix")} position="end">
          {suffix}
        </InputAdornment>
      ),
    }
  }

  let Type = type
  if (type === "email" || type === "number") {
    Type = "text"
  }
  return (
    <MatTextField
      {...props}
      fullWidth
      className={bem(undefined, undefined, className)}
      error={!!errorText}
      InputProps={inputProps}
      inputRef={ref}
      required={required}
      type={Type}
      value={value ?? ""}
      variant={variant}
      FormHelperTextProps={{
        classes: {
          root: bem("helperText__wrapper"),
        },
      }}
      helperText={
        errorText ||
        helperText ||
        (!!max && !!value && (value as string)?.toString()?.length > 0) ? (
          <span className={bem("helperText")}>
            <span className={bem("helperText__content")}>
              {errorText ?? helperText}
            </span>
            {type !== "number" && !!max && !!value ? (
              <span className={bem("helperText__legend")}>
                {(value as string)?.toString()?.length ?? 0} / {max}
              </span>
            ) : null}
          </span>
        ) : undefined
      }
      inputProps={{
        ...props?.inputProps,
        min,
        max,
        minLength: min,
        maxLength: max,
      }}
      onChange={
        onChange &&
        ((e) => {
          setCursor({
            selectionStart: e.target.selectionStart,
            selectionEnd: e.target.selectionEnd,
          })
          handleValidation?.(e)
          onChange(e)
        })
      }
    />
  )
}
