import {
  useHookstate,
  State,
  none,
  SetPartialStateAction,
} from "@hookstate/core"

import {
  AdvancedForms,
  AdvancedFormFieldProps,
} from "src/components/common/advancedForm"
import { conditionalUpdate } from "src/helpers/form"
import {
  xRMApiStep,
  xRMApiForm,
  xRMApiField,
  xRMApiSalesorder,
  xRMApiSalesorderType,
  xRMApiSalesorderPhase,
  xRMApiPlacement,
} from "src/types/xRM"

import { createAspState } from "./createAspState"

export interface BookingCoExhibitorChildren {
  [p: string]: any
}

export interface BookingCoExhibitor {
  name: string
  children?: BookingCoExhibitorChildren[]
  [p: string]:
    | number
    | string
    | xRMApiSalesorderPhase
    | xRMApiSalesorderType
    | BookingCoExhibitorChildren[]
    | undefined
}

export interface BookingDetails {
  activeStep: number
  steps: xRMApiStep[]
}

export interface BookingDetail {
  loadingSalesorderDeletion?: boolean
  coExhibitors: BookingCoExhibitor[]
  placements?: xRMApiPlacement[]
  booking?: xRMApiSalesorder
  detail: BookingDetails
}

export const defaultState = {
  coExhibitors: [],
  detail: {
    activeStep: 0,
    steps: [],
  },
} as BookingDetail

const state = createAspState<BookingDetail>(defaultState, "booking-detail")

/************ BOOKING ***********/

/**
 * Merges data in the booking state.
 * @param data the data for the booking
 */
export const mergeBooking = (data: xRMApiSalesorder) => {
  state.booking.merge({
    ...data,
  })
}

/**
 * Set data in the booking state.
 * @param data the data for the booking
 */
export const setBooking = (data: xRMApiSalesorder) => {
  state.booking.set({
    ...data,
  })
}

/**
 * Resets the booking salesorder state
 */
export const resetBooking = () => state.booking.set(none)

/**
 * Returns booking added to the state.
 * @returns the xRMApiSalesorder
 */
export const getBooking = async () => state.booking.get()

/************ PLACEMENTS ***********/

/**
 * Set data in the booking state.
 * @param data the data for the booking
 */
export const setPlacements = (data: xRMApiPlacement[]) => {
  state.placements.set(data)
}

/**
 * Resets the booking salesorder state
 */
export const resetPlacements = () => state.placements.set(none)

/**
 * Returns booking added to the state.
 * @returns the xRMApiSalesorder
 */
export const getPlacements = async () => state.placements.get()

/************ DETAILS ***********/

/**
 * Sets the state.
 * @param items the data for the booking
 */
export const setBookingDetails = (items: BookingDetails) =>
  state.detail.set(items)

/**
 * Merges data in the detail state.
 * @param form the data for the form
 */
export const mergeDetailForm = (form: AdvancedForms) => {
  const activeStep = state.detail.steps.nested(state.detail.activeStep.get())
  if (activeStep) {
    activeStep.forms.merge({
      ...form,
    })
  }
}

/**
 * Changes a existing field
 * @param index the index of the form
 * @param items the changed attributes of the field
 * @param fieldId the id of the field
 */
export const mergeDetailFormField = async (
  index: number,
  items: AdvancedFormFieldProps,
  fieldId: string | null,
) => {
  const activeStepIndex = state?.detail?.activeStep.get()
  const activeStep = (state?.detail?.steps as State<xRMApiStep[]>).nested(
    activeStepIndex,
  )
  const activeForm =
    (activeStep.forms as State<xRMApiForm[]>).nested(index) ?? null
  // Call only if field changes value
  if (
    items.value !== undefined &&
    (typeof items.value === "boolean" ||
      typeof items.value === "string" ||
      typeof items.value === "number" ||
      Array.isArray(items.value)) &&
    fieldId
  ) {
    let values = [items?.value]
    if (Array.isArray(values?.[0])) {
      values = values.flat() as any
    }
    for (let i = 0; i < values.length; i++) {
      await conditionalUpdate(
        state?.detail?.steps as State<xRMApiStep[]>,
        activeStep,
        activeForm,
        fieldId,
        values[i],
      )
    }
  }
  const fieldIndex = (activeForm.fields.get() as xRMApiField[]).findIndex(
    (e) => e.id === fieldId,
  )
  const field = (activeForm.fields as State<xRMApiField[]>).nested(fieldIndex)
  field.merge({
    ...(items as xRMApiField),
  })
}

/**
 * Sets the active step
 * @param activeStep the index of the form
 */
export const setDetailActiveStep = (activeStep: number) =>
  state.detail.merge({ activeStep })

/**
 * Resets the details of booking
 */
export const resetDetail = () =>
  state.detail.set({
    activeStep: 0,
    steps: [],
  })

/************ CO-EXHIBITORS ***********/

/**
 * Merges data in the co-exhibitors state.
 * @param data the data of the co-exhibitors
 */
export const setCoExhibitors = (data: BookingCoExhibitor[]) => {
  state.coExhibitors.set(data)
}

/**
 * Remove co-exhibitor by id
 */
export const removeCoExhibitorById = (name: string) => {
  const index = state?.coExhibitors?.get()?.findIndex((el) => el.name === name)
  state.coExhibitors.nested(index).set(none)
}

/**
 * Resets the co-exhibitors state
 */
export const resetCoExhibitors = () => state.coExhibitors.set([])

/**
 * Returns  co-exhibitors added to the state.
 * @returns the BookingCoExhibitor
 */
export const getCoExhibitors = () => state.coExhibitors.get()

/************ GENERALS ***********/

/**
 * Returns the complete state.
 * @returns the BookingDetail
 */
export const getBookingDetail = async () => state.get()

/**
 * Sets the state.
 * @param items the data for the booking
 */
export const setBookingDetail = (items: BookingDetail) => state.set(items)

/**
 * Updates the state.
 * @param items the data for the booking
 */
export const mergeBookingDetail = (
  items: SetPartialStateAction<BookingDetail>,
) => state.merge(items)

/**
 * React hook to receive complete state as hook.
 * @returns the Booking
 */
export const useBooking = () => {
  return useHookstate(state).get()
}
