import CloseIcon from "@mui/icons-material/Close"
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined"
import LinearProgress from "@mui/material/LinearProgress"
import { useRouter } from "next/router"
import React, { FC, useMemo } from "react"


import { Button } from "src/components/common/button"
import { Drawer } from "src/components/common/drawer"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { create } from "src/helpers/bem"

import { deepClone } from "src/helpers/deepClone"
import {
  postPurchaseorder,
  postPurchaseorderRefresh,
} from "src/queriesXRM/purchaseorder"
import { useLocale } from "src/states/locale"
import {
  useCarts,
  mergeCarts,
  useCart,
  CartProduct,
  resetCart,
  setCart,
} from "src/states/shop/carts"
import { TranslationMessages } from "src/translations"

import { PagesProps } from "src/types/SharedPageProps"
import { xRMApiPurchaseorder } from "src/types/xRM"


import { ShopCartProductCard } from "../ShopCartProductCard"

import styles from "./ShopCartDialog.module.scss"

const bem = create(styles, "ShopCartDialog")

export type ShopCartDialogProps = {
  messages: TranslationMessages
  pages: PagesProps
  style: BasicLayoutStyleProps
}

export const ShopCartDialog: FC<ShopCartDialogProps> = ({
  messages,
  pages,
  style,
}) => {
  const m = messages.pages.shop.cart.dialog
  const { loading: loadingCarts, cartDialogOpen } = useCarts()
  const cart = useCart()
  const locale = useLocale()
  const router = useRouter()

  const buttonDisabled =
    !cart.products || cart.products?.length === 0 || loadingCarts

  const checkout = async () => {
    mergeCarts({ loading: true })
    const purchaseorder: xRMApiPurchaseorder = {
      parentSalesorderId: cart.id,
      salesorderId: cart.orderId,
      exhibitionId: process.env.NEXT_PUBLIC_XRM_EVENT_ID,
      products: deepClone<CartProduct[]>(cart.products),
    }
    const { mustRefresh } = await postPurchaseorder(purchaseorder)
    if (mustRefresh) {
      const refreshed = await postPurchaseorderRefresh(purchaseorder)
      setCart(refreshed.products ?? [])
    } else {
      resetCart()
    }
    mergeCarts({ loading: false, cartDialogOpen: mustRefresh })
  }

  const webshopCartOverviewPage = useMemo(() =>
      pages?.data?.nodes?.filter(page =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Webshop Cart Overview Template",
      )?.[0],
    [pages, locale]
  )

  return (
    <Drawer
      anchor="right"
      open={cartDialogOpen}
      style={style}
      onClose={() => mergeCarts({ cartDialogOpen: false })}
      onOpen={() => mergeCarts({ cartDialogOpen: true })}
    >
      {loadingCarts && <LinearProgress color="primary" />}
      <div className={bem()}>
        <div className={bem("header")}>
          <div className={bem("counter")}>
            <ShoppingBagOutlinedIcon className={bem("counter__icon")} />
            <h3 className={bem("counter__title")}>
              {cart.products?.length ?? 0}{" "}
              {cart.products && cart.products?.length > 1 ? m.title2 : m.title2}
            </h3>
          </div>
          <button
            className={bem("close")}
            onClick={() => mergeCarts({ cartDialogOpen: false })}
          >
            <CloseIcon className={bem("close__icon")} />
          </button>
        </div>
        <div className={bem("products")}>
          {cart.products.map(product =>
            <ShopCartProductCard
							key={product.productVariant?.id}
							item={product}
						/>)}
        </div>
        <div className={bem("footer")}>
          <Button
            className={bem("button")}
            disabled={buttonDisabled}
            title={m.buttons.checkout}
            variant="contained"
            onClick={() => {
              console.log("JUMP TO CHECKOUT!")
              // TODO: ADD ROUTER TO JUMP TO DETAILED CHECKOUT PAGE
            }}
          />
          <Button
            disabled={buttonDisabled}
            title={m.buttons.cart}
            variant="outlined"
            classes={{
              root: bem("button"),
            }}
            onClick={async () => {
              mergeCarts({ cartDialogOpen: false })
              await router.push(webshopCartOverviewPage?.uri as string)
            }}
          />
          <Button
            disabled={buttonDisabled}
            title={m.buttons.send}
            variant="outlined"
            classes={{
              root: bem("button"),
            }}
            onClick={() => checkout()}
          />
        </div>
      </div>
    </Drawer>
  )
}
