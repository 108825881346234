import { useRouter } from "next/router"
import { FC, useCallback, useMemo, useState } from "react"
import { Dialog } from "src/components/common/dialog"
import { InputField } from "src/components/common/inputfield"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"
import { create } from "src/helpers/bem"
import { postPurchaseorderPermission } from "src/queriesXRM/purchaseorder"
import { useLocale } from "src/states/locale"
import { mergeCarts, useCarts } from "src/states/shop/carts"
import { TranslationMessages } from "src/translations"

import { PagesProps } from "src/types/SharedPageProps"

import styles from "./ShopOverviewStandRequestDialog.module.scss"

const bem = create(styles, "ShopOverviewStandRequestDialog")

export type ShopOverviewStandRequestDialogProps = {
	messages: TranslationMessages
	pages: PagesProps
	style: BasicLayoutStyleProps
}

type InputValue = {
	value: string,
	error: string | undefined
}

const defaultValues: { [key: string]: InputValue} = {
	standhall: {
		value: "",
		error: undefined
	},
	standnumber: {
		value: "",
		error: undefined
	},
	standsize: {
		value: "",
		error: undefined
	}
}

export const ShopOverviewStandRequestDialog: FC<ShopOverviewStandRequestDialogProps> = ({ messages, pages, style }) => {
	const [values, setValues] = useState(defaultValues)
	const { salesorderRequestDialogOpen } = useCarts()
	const locale = useLocale()
	const router = useRouter()

	const m = messages.pages.shop.overview.salesorderRequestDialog
	const hasFormErrors = Object.values(values).every(v => !!v.error)
	const hasFormRequiredValues = Object.values(values).every(v => !!v.value)

	const redirectStandBookingRequestsOverviewForm = useMemo(() =>
      pages?.data?.nodes?.filter(page =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Webshop Standrequests Overview Template",
      )?.[0],
    [pages, locale],
  )

	const handleSend = useCallback(async () => {
		await postPurchaseorderPermission({
			standHall: values.standhall.value,
			standName: values.standnumber.value,
			standSize: values.standsize.value,
		})
		await router.push(redirectStandBookingRequestsOverviewForm?.uri as string)
		setValues(defaultValues)
	}, [redirectStandBookingRequestsOverviewForm?.uri, router, values.standhall.value, values.standnumber.value, values.standsize.value])

	const handleCancel = useCallback(() => {
		mergeCarts({ salesorderDialogOpen: true })
		setValues(defaultValues)
	}, [])

	const handleClose = () => {
		mergeCarts({ salesorderRequestDialogOpen: false })
		setValues(defaultValues)
	}

	const firstRowButtons = useMemo(() => [
    {
      title: m.buttons.send,
      disabled: hasFormErrors || !hasFormRequiredValues,
      onClick: () => handleSend()
    },
    {
      title: m.buttons.cancel,
      disabled: false,
      onClick: () => handleCancel()
    },
  ], [handleCancel, handleSend, hasFormErrors, hasFormRequiredValues, m.buttons.cancel, m.buttons.send])

  return (
  	<Dialog
		closeOnClick
		firstRowButtons={firstRowButtons}
		open={useMemo(() => salesorderRequestDialogOpen, [salesorderRequestDialogOpen])}
		style={style}
		title={m.title}
		onClose={() => handleClose()}
	>
		<div className={bem()}>
			<p className={bem("desc")}>{m.desc}</p>
			<form className={bem("form")}>
				<InputField
					required
					errorText={values.standhall.error}
					label={m.form.inputs.standhall.label}
					value={values.standhall.value}
					onChange={e => setValues({ ...values, standhall: { ...values.standhall, value: e.target.value }})}
					onValidate={err => setValues({ ...values, standhall: { ...values.standhall, error: err }})} />
				<InputField
					required
					errorText={values.standnumber.error}
					label={m.form.inputs.standnumber.label}
					value={values.standnumber.value}
					onChange={e => setValues({ ...values, standnumber: { ...values.standnumber, value: e.target.value }})}
					onValidate={err => setValues({ ...values, standnumber: { ...values.standnumber, error: err }})} />
				<InputField
					required
					errorText={values.standsize.error}
					label={m.form.inputs.standsize.label}
					value={values.standsize.value}
					onChange={e => setValues({ ...values, standsize: { ...values.standsize, value: e.target.value }})}
					onValidate={err => setValues({ ...values, standsize: { ...values.standsize, error: err }})} />
			</form>
		</div>
	</Dialog>
  )
}