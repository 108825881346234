import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
  createRef,
  RefObject,
  forwardRef,
  useRef,
  ReactNode,
  useImperativeHandle,
} from "react"

import { create } from "src/helpers/bem"

import styles from "./Dropdown.module.scss"

const bem = create(styles, "Dropdown")

export interface DropdownRef {
  close(): void
}

export type DropdownProps = {
  button: ReactElement
  children: ReactNode
}

function useOnClickOutside(
  ref: RefObject<HTMLDivElement | undefined>,
  handler: (e: Event) => void,
) {
  useEffect(() => {
    const listener = (e: Event) => {
      if (!ref.current || ref.current.contains(e?.target as Element)) {
        return
      }

      handler?.(e)
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handler])
}

export const Dropdown = forwardRef<DropdownRef | undefined, DropdownProps>(
  ({ button, children }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const buttonRef = createRef<HTMLDivElement>()
    const [open, setOpen] = useState(false)
    const [buttonDimensions, setButtonDimensions] = useState({
      width: 0,
      height: 0,
    })

    useImperativeHandle(ref, () => ({
      close() {
        setOpen(false)
      },
    }))

    useLayoutEffect(() => {
      if (buttonRef?.current && buttonDimensions?.width === 0) {
        setButtonDimensions({
          width: buttonRef.current.offsetWidth,
          height: buttonRef.current.offsetHeight,
        })
      }
    }, [])

    const modifiers = {
      "is-open": open,
    }
    const handler = useCallback(() => setOpen(false), [])
    useOnClickOutside(containerRef, handler)
    return (
      <div ref={containerRef} className={bem()}>
        <div
          ref={buttonRef}
          className={bem("button")}
          onClick={() => setOpen(!open)}
        >
          {button}
          <span
            className={bem("content", modifiers)}
            style={{ top: buttonDimensions?.height + 5 }}
          >
            {children}
          </span>
        </div>
      </div>
    )
  },
)

Dropdown.displayName = "Dropdown"
