import MatCheckbox from "@mui/material/Checkbox"
import { CheckboxProps as MatCheckboxProps } from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import FormHelperText from "@mui/material/FormHelperText"

import React, { FC } from "react"

import { create } from "src/helpers/bem"

import styles from "./Checkbox.module.scss"

const bem = create(styles, "Checkbox")

export type CheckboxProps = {
  variant?: "standard" | "filled" | "outlined"
  helperText?: string
  errorText?: string
  label?: string
} & MatCheckboxProps

export const Checkbox: FC<CheckboxProps> = ({
  value = false,
  variant = "filled",
  checked = false,
  label,
  required,
  helperText,
  errorText,
  ...props
}) => {
  return (
    <FormControl
      required
      component="fieldset"
      error={!!errorText}
      variant={variant}
    >
      <FormGroup>
        <FormControlLabel
          control={<MatCheckbox checked={checked} {...props} value={value} />}
          label={required ? `${label}*` : label}
        />
      </FormGroup>
      {errorText ||
        (helperText && (
          <FormHelperText className={bem("helperText")}>
            {errorText ?? helperText}
          </FormHelperText>
        ))}
    </FormControl>
  )
}
