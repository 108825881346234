import LinearProgress from "@mui/material/LinearProgress"
import React, { FC, useState, useMemo } from "react"

import { Dialog } from "src/components/common/dialog"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"
import { create } from "src/helpers/bem"

import { deleteFileById } from "src/queriesXRM/file"

import { useBooking } from "src/states/bookingDetail"
import {
  useBookingDetailFiles,
  mergeBookingDetailFiles,
  removeBookingDetailFile,
} from "src/states/bookingDetailFiles"

import { TranslationMessages } from "src/translations"

import styles from "./DeleteFileDialog.module.scss"

const bem = create(styles, "DeleteFileDialog")

export type DeleteFileDialogProps = {
  messages: TranslationMessages
  style: BasicLayoutStyleProps
}

export const DeleteFileDialog: FC<DeleteFileDialogProps> = ({
  messages,
  style,
}) => {
  const [loading, setLoading] = useState(false)
  const m = messages.pages.booking.detail.actions.deleteDocument
  const { booking } = useBooking()
  const { activeFileId, open } = useBookingDetailFiles()

  const onDeleteFile = () => {
    setLoading(true)
    return deleteFileById(activeFileId as string, booking?.id)
      .then(() => {
        setLoading(false)
        removeBookingDetailFile(activeFileId as string)
        mergeBookingDetailFiles({
          open: false,
          activeFileId: undefined,
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog
      closeOnClick
      fullWidth
      color="error"
      maxWidth="sm"
      open={useMemo(() => open, [open]) ?? false}
      style={style}
      title={m.title}
      firstRowButtons={[
        {
          title: m.delete,
          disabled: loading,
          color: "error",
          variant: "text",
          onClick: () => onDeleteFile(),
        },
        {
          title: m.cancel,
          variant: "text",
          onClick: () =>
            mergeBookingDetailFiles({
              open: false,
              activeFileId: undefined,
            }),
        },
      ]}
    >
      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <p className={bem("content")}>{m.content}</p>
      )}
    </Dialog>
  )
}
