import { State, none } from "@hookstate/core"

import {
  AdvancedForms,
  AdvancedFormFieldProps,
} from "src/components/common/advancedForm"
import { deleteBlob } from "src/queriesXRM/file"
import {
  xRMApiStep,
  xRMApiForm,
  xRMApiField,
  xRMApiDependency,
  xRMApiDependencyTarget,
  xRMApiPostForm,
  xRMApiFormType,
  xRMApiSalesorderPhase,
  xRMApiFile,
} from "src/types/xRM"

export const formatFormData = async (
  formType: xRMApiFormType,
  activeStep: number,
  data: ReadonlyArray<xRMApiStep>,
  parentSalesorderId?: string,
  salesorderId?: string | null,
  phase?: xRMApiSalesorderPhase,
) => {
  let Data = {
    exhibitionId: process.env.NEXT_PUBLIC_XRM_EVENT_ID,
    formType,
    parentSalesorderId,
    salesorderId: salesorderId ?? undefined,
    // TODO: Find a dynamic way for iteration 2
    salesorderType: "StandBooking",
    values: [],
    currentForm: JSON.stringify({
      activeStep,
      steps: data,
    }),
  } as xRMApiPostForm
  data.forEach((step: xRMApiStep) => {
    step.forms?.forEach((form) => {
      Data.values?.push({
        field: form?.id,
        conditions: form?.conditions,
        defaultConditions: form?.defaultConditions,
      })
      if (
        form?.conditions?.display === undefined ||
        form?.conditions?.display === true
      ) {
        form.fields?.forEach((field) => {
          let pushValue =
            field?.type === "checkbox" ||
            ((field?.conditions?.display === undefined ||
              field?.conditions?.display === true) &&
              field?.value !== undefined)

          Data.values?.push({
            value: pushValue ? field?.value : null,
            field: field?.field,
            referenceType: field?.referenceType,
            conditions: field?.conditions,
            defaultConditions: field?.defaultConditions,
            errorText: field?.errorText,
          })
        })
      }
    })
  })
  return JSON.parse(JSON.stringify(Data))
}

// DEPENDENCY LOGIC FOR ADVANCED FORM STATES
const getFormOrField = async (
  state: State<xRMApiStep[]>,
  form: State<xRMApiForm>,
  target: xRMApiDependencyTarget,
) => {
  const stepIndex = state.get().findIndex((e) => e.id === target.stepId)
  const formIndex = (state.nested(stepIndex).forms as State<xRMApiForm[]>)
    .get()
    .findIndex((e) => e.id === target.formId)
  const formState = (
    state.nested(stepIndex).forms as State<xRMApiForm[]>
  ).nested(formIndex)
  // Return form if fieldId is not available
  if (!target.fieldId) {
    return {
      data: formState.get() as AdvancedForms,
      state: formState,
    }
  }
  const fieldDependencyIndex = (
    formState?.fields?.get() as xRMApiField[]
  )?.findIndex((e) => e.id === target.fieldId)
  const fieldState = (form.fields as State<xRMApiField[]>).nested(
    fieldDependencyIndex,
  )
  return {
    data: fieldState?.get() as AdvancedFormFieldProps,
    state: (form.fields as State<xRMApiField[]>).nested(fieldDependencyIndex),
  }
}

const mergeConditions = async (
  state: State<xRMApiStep[]>,
  reset: boolean,
  form: State<xRMApiForm>,
  targets?: xRMApiDependencyTarget[],
) => {
  if (!targets || targets?.length === 0) {
    return
  }
  targets.flat().forEach(async (target) => {
    if (reset && target?.items) {
      return null
    }
    // Get field if fieldId is available. If not return form.
    const element = await getFormOrField(state, form, target)
    const field = element?.data as AdvancedFormFieldProps
    // Remove files from storage on reset of file field
    if (
      reset &&
      field?.type === "file" &&
      field?.id &&
      (field?.value as xRMApiFile[])?.length > 0
    ) {
      ;(field?.value as xRMApiFile[]).map((file) => {
        if (!file?.name) {
          return
        }
        deleteBlob(field?.id ?? "", file.name)
      })
    }
    // Reset conditions to default
    let updatedItems = {
      conditions: element.data?.defaultConditions,
      defaultConditions: none,
      errorText: "",
      value: "",
    } as xRMApiField
    if (!reset && element.data?.conditions) {
      // If choosen value matches with dependency value
      updatedItems = {
        ...updatedItems,
        conditions: target.conditions,
        defaultConditions: element.data?.conditions,
      } as xRMApiField
    }
    if (target?.items) {
      updatedItems = {
        ...updatedItems,
        items: target.items,
      }
    }
    // Reset form fields if condition will be used in form
    const formFields = (element?.data as AdvancedForms)?.fields
    if (formFields?.length > 0) {
      const resetedFields = JSON.parse(JSON.stringify(formFields)).map(
        (field: xRMApiField) => ({
          ...field,
          errorText: "",
          value: "",
        }),
      )
      updatedItems = {
        ...updatedItems,
        fields: resetedFields,
      } as xRMApiForm
    }
    if (element?.state) {
      element.state.merge(JSON.parse(JSON.stringify(updatedItems)))
    }
  })
}

export const conditionalUpdate = async (
  state: State<xRMApiStep[]>,
  step: State<xRMApiStep>,
  form: State<xRMApiForm>,
  fieldId: string,
  value?: string | number | boolean | string[] | xRMApiFile[],
) => {
  // Filter for all field dependencies
  const fieldDependencies = (
    step.dependencies.get() as xRMApiDependency[]
  )?.filter((dep) => dep?.key === fieldId)
  // Check if field has dependencies
  if (fieldDependencies && fieldDependencies?.length > 0) {
    // Reset conditions if value doesn't match with active field
    const resetTargets = fieldDependencies
      .filter((el) => el?.value !== value)
      .map((el) => el.targets)
    await mergeConditions(
      state,
      true,
      form,
      resetTargets as xRMApiDependencyTarget[],
    )
    // Set conditions if value matches with active field
    const activeTargets = fieldDependencies
      .filter((el) => el?.value === value)
      .map((el) => el.targets)
    await mergeConditions(
      state,
      false,
      form,
      activeTargets as xRMApiDependencyTarget[],
    )
  }
  return
}

export const sortArrayByPosition = (e: xRMApiForm[] | xRMApiField[]) =>
  e?.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
