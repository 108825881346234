import LinearProgress from "@mui/material/LinearProgress"
import React, { FC, useEffect, useState } from "react"

import { v4 } from "uuid"

import {
  AdvancedForm,
  AdvancedFormStepProps,
} from "src/components/common/advancedForm"

import { create } from "src/helpers/bem"
import { fetchCoExhibitors } from "src/helpers/fetchQueriesXRM"
import { formatFormData } from "src/helpers/form"
import { getFilesById } from "src/queriesXRM/file"
import { getForm, postForm } from "src/queriesXRM/form"

import { useBooking } from "src/states/bookingDetail"
import {
  useCoExhibitorInvite,
  setCoExhibitorInviteActiveStep,
  mergeCoExhibitorInvite,
  resetCoExhibitorSelfInvite,
  getCoExhibitorInvite,
  setCoExhibitorSelfInviteSteps,
  mergeCoExhibitorSelfInviteFormById,
  mergeCoExhibitorSelfInviteStepById,
  mergeCoExhibitorSelfInviteField,
  mergeCoExhibitorSelfInviteFieldById,
} from "src/states/coExhibitorInvite"
import { useLocale } from "src/states/locale"

import { TranslationMessages } from "src/translations"
import { xRMApiField, xRMApiFile, xRMApiStep } from "src/types/xRM"

import styles from "./AddCoExhibitorForm.module.scss"

const bem = create(styles, "AddCoExhibitorForm")

export type AddCoExhibitorFormProps = {
  messages: TranslationMessages
}

export const AddCoExhibitorForm: FC<AddCoExhibitorFormProps> = ({
  messages,
}) => {
  const [loading, setLoading] = useState(false)
  const formTranslations =
    messages.pages.booking.detail.actions.addCoExhibitor.selfEntry.form
  const { booking } = useBooking()
  const { salesorderId, open, activeStep, selfEntry } = useCoExhibitorInvite()
  const locale = useLocale()

  const loadForm = async () => {
    const data = await getCoExhibitorInvite()
    setLoading(true)
    const form = await getForm("CoExhibitor")
    if (data.selfEntry.length !== 0) {
      form.map((step: xRMApiStep) => {
        mergeCoExhibitorSelfInviteStepById(
          {
            id: step.id,
            position: step.position,
            label: step.label,
            dependencies: step.dependencies,
          },
          step.id,
        )
        return step?.forms?.map((form) => {
          mergeCoExhibitorSelfInviteFormById(
            {
              id: form.id,
              position: form.position,
              label: form.label,
              helperText: form.helperText,
              fields: form.fields,
            },
            step.id,
            form.id,
          )
          return form?.fields?.map(async (field) => {
            let Field = field
            delete Field.conditions
            delete Field.value
            if (Field.type === "file") {
              const defaultFiles = await getFilesById(salesorderId)
              const filteredDefaultFiles = defaultFiles?.filter(
                (f: xRMApiFile) => f.formControl === "",
              ) // Before filtered name of field
              Field = {
                ...Field,
                files: filteredDefaultFiles,
              } as xRMApiField
            }
            return mergeCoExhibitorSelfInviteFieldById(
              {
                ...Field,
              },
              step.id,
              form.id,
              field.id,
            )
          })
        })
      })
    } else {
      setCoExhibitorSelfInviteSteps(form)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      setCoExhibitorInviteActiveStep(0)
    }
    loadForm()
  }, [locale, open])

  const onSendForm = async () => {
    setLoading(true)
    const data = await formatFormData(
      "CoExhibitor",
      activeStep,
      selfEntry as ReadonlyArray<xRMApiStep>,
      booking?.id ?? undefined,
      salesorderId,
    )
    return postForm(data)
      .then(async () => {
        await fetchCoExhibitors(booking?.id ?? "")
        mergeCoExhibitorInvite({
          open: false,
          activeStep: 0,
          selfEntry: [],
          salesorderId: v4(),
        })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  if (loading) {
    return <LinearProgress color="primary" />
  }
  return (
    <div className={bem()}>
      <AdvancedForm
        stepLabelsClickable
        activeStep={activeStep}
        data={selfEntry as AdvancedFormStepProps[]}
        formMessages={formTranslations}
        handleChangeActiveStep={setCoExhibitorInviteActiveStep}
        handleMergeField={mergeCoExhibitorSelfInviteField}
        handleReset={resetCoExhibitorSelfInvite}
        id={salesorderId}
        messages={messages}
        buttons={{
          backButtonLabel: formTranslations.buttons.back,
          nextButtonLabel: formTranslations.buttons.next,
          finishButtonLabel: formTranslations.buttons.finish,
        }}
        onSendForm={onSendForm}
      />
    </div>
  )
}
