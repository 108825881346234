import xrmApi from "src/services/xrmApi"

import { mergeAccount } from "src/states/account"
import { mergeAccountForm } from "src/states/accountForm"
import { xRMApiAccount } from "src/types/xRM"

export const getAccount = async () => {
  return xrmApi.get("/account").then((res) => {
    if (!res?.data) {
      return
    }
    mergeAccount(res.data)
    return Object.keys(res.data).map((key) => {
      if (!key?.includes("company")) {
        mergeAccountForm(
          0,
          {
            value: res.data?.[key] ?? "",
          },
          key,
        )
        return
      }
      mergeAccountForm(
        1,
        {
          value: res.data?.[key] ?? "",
        },
        key,
      )
    })
  })
}

export const postAccount = async (data: xRMApiAccount) => {
  return xrmApi.post("/account", data).then((res) => {
    if (res?.data) {
      mergeAccount(res.data)
    }
    return res
  })
}
