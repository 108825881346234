import { createTheme } from "@mui/material/styles"
import { AppProps as NextAppProps } from "next/app"
import Head from "next/head"
import React, { FC, useEffect, useState } from "react"

import { Fonts } from "src/components/core/fonts"
import { Providers } from "src/components/core/providers"
import { useLocale, setLocale } from "src/states/locale"
import { setStateVersion, useStateVersion } from "src/states/version"
import { DEFAULT_LOCALE, isLocale, Locale } from "src/translations"
import { SharedPageProps } from "src/types/SharedPageProps"
import "what-input"

import "../styles/wordpress/styles.css"
import "../assets/icons/_fontawesome.css"
import "./_app.scss"

type AppProps = NextAppProps<SharedPageProps>

const App: FC<AppProps> = ({ Component: Page, pageProps }) => {
  const [hasMounted, setHasMounted] = useState(false)
  const clientStateVersion = useStateVersion()
  const { messages, generalSettings, page, typography, color } =
    pageProps as SharedPageProps
  const meta = page?.data?.meta
  const locale = useLocale()

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true)
    }

    let navigatorLocale = navigator?.language?.split("-")?.[0] as Locale
    let windowLocale = window?.location?.pathname?.split("/")?.[1] as Locale

    if (isLocale(windowLocale)) {
      setLocale(windowLocale)
    } else if (!locale) {
      setLocale(navigatorLocale ?? DEFAULT_LOCALE)
    }

    if (clientStateVersion < +process.env.APP_STATE_VERSION!) {
      localStorage.clear()
      setStateVersion(+process.env.APP_STATE_VERSION!)
    }
  }, [])

  if (!hasMounted) {
    return null
  }

  let title
  if (generalSettings?.data?.themeOptions?.generals?.subtitle) {
    title = `${generalSettings?.data?.themeOptions?.generals?.title} - ${generalSettings?.data?.themeOptions?.generals?.subtitle}`
  } else {
    title = generalSettings?.data?.themeOptions?.generals?.title
  }

  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily:
        typography?.data?.themeOptions?.typography?.body?.fontFamily ?? "Lato",
    },
    palette: {
      primary: {
        main: color?.data?.themeOptions?.color?.primary ?? "#3F54B5",
      },
      secondary: {
        main: color?.data?.themeOptions?.color?.secondary ?? "#3E9EBB",
      },
      info: {
        main: "#ccc",
      },
    },
  })

  return (
    <Providers messages={messages} theme={theme}>
      <Head>
        <link href="https://app.usercentrics.eu" rel="preconnect" />
        <link href="https://api.usercentrics.eu" rel="preconnect" />
        <link
          as="script"
          href="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          rel="preload"
        />
        <title>
          {title} - {page?.data?.title}
        </title>
        <meta content={meta?.description ?? ""} name="description" />
        <meta content={meta?.keywords ?? ""} name="keywords" />
        <link
          href={generalSettings?.data?.themeOptions?.generals?.favicon ?? ""}
          rel="shortcut icon"
        />
        <script
          async
          data-settings-id={`${process.env.USER_CENTRICS_ID}`}
          id="usercentrics-cmp"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        ></script>
      </Head>
      <Fonts />
      <Page {...pageProps} />
    </Providers>
  )
}

export default App
