import Box from "@mui/material/Box"
import Tab, { TabProps as MatTabProps } from "@mui/material/Tab"
import MatTabs, { TabsProps as MatTabsProps } from "@mui/material/Tabs"

import DOMPurify from "isomorphic-dompurify"
import React, { FC, ReactNode, useState } from "react"

import { create } from "src/helpers/bem"

import styles from "./Tabs.module.scss"

const bem = create(styles, "Tabs")

export type TabPanelProps = {
  id: string
  className?: string
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, id, value, index, ...other } = props
  return (
    <div
      aria-labelledby={`${id}-${index}`}
      hidden={value !== index}
      id={`${id}-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function TabProps(id: string, index: number) {
  return {
    id: `${id}-${index}`,
    "aria-controls": `${id}-${index}`,
  }
}

export type TabItem = {
  label: string
  labelInfo?: string
  node: ReactNode | string
} & MatTabProps

export type TabsProps = {
  id: string
  items: TabItem[]
} & MatTabsProps

export const Tabs: FC<TabsProps> = ({
  id,
  items,
  value = 0,
  onChange,
  ...props
}) => {
  const [Value, setValue] = useState(value)

  if (items?.length === 0) {
    return null
  }
  return (
    <>
      <Box className={bem()} sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MatTabs
          {...props}
          centered
          indicatorColor="primary"
          textColor="inherit"
          value={Value ?? 0}
          variant="fullWidth"
          onChange={(e, val) => {
            setValue(val)
            onChange?.(e, val)
          }}
        >
          {items.map((item, i) => {
            const labelInfo = item.labelInfo
            if (item?.labelInfo) {
              delete item.labelInfo
            }
            return (
              <Tab
                {...item}
                key={`${id}-${i}-tab`}
                className={bem("tab")}
                label={
                  <div className={bem("tab__label__wrapper")}>
                    {labelInfo ? (
                      <span className={bem("tab__label__info")}>
                        {labelInfo}
                      </span>
                    ) : null}
                    <span className={bem("tab__label")}>{item.label}</span>
                  </div>
                }
                {...TabProps(id, i)}
              />
            )
          })}
        </MatTabs>
      </Box>
      {items.map((item, i) => (
        <TabPanel key={`${id}-${i}-panel`} id={id} index={i} value={Value}>
          {typeof item?.node === "string" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.node),
              }}
            />
          ) : (
            item?.node
          )}
        </TabPanel>
      ))}
    </>
  )
}
