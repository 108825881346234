import Divider from "@mui/material/Divider"
import LinearProgress from "@mui/material/LinearProgress"
import { useRouter } from "next/router"
import React, { FC, useEffect, useState, useRef, useMemo } from "react"

import { DropdownRef } from "src/components/common/dropdown"

import { create } from "src/helpers/bem"
import { deepClone } from "src/helpers/deepClone"
import { getFilesById } from "src/queriesXRM/file"
import { getFormById } from "src/queriesXRM/form"
import { getPermission } from "src/queriesXRM/permission"
import { getPlacements } from "src/queriesXRM/placement"
import { getSalesorderById } from "src/queriesXRM/salesorder"

import { useAccount, mergeAccount } from "src/states/account"
import {
  BookingDetails,
  getBookingDetail,
  setBooking,
  setBookingDetails,
  setPlacements,
  useBooking,
} from "src/states/bookingDetail"
import { useLocale } from "src/states/locale"

import { resetSalesorder, setSalesorderSteps } from "src/states/salesorder"
import { SharedPageProps } from "src/types/SharedPageProps"

import { xRMApiForm, xRMApiField, xRMApiFile, xRMApiStep } from "src/types/xRM"

import styles from "./BookingDetail.module.scss"

import { BookingDetailBanner } from "./BookingDetailBanner"
import { BookingDetailCoExhibitorTable } from "./BookingDetailCoExhibitorTable"
import { BookingDetailDropdown } from "./BookingDetailDropdown"
import { BookingDetailFileManagement } from "./BookingDetailFileManagement"
import { BookingDetailForm } from "./BookingDetailForm"
import { BookingDetailInfo } from "./BookingDetailInfo"
import { BookingDetailPlacement } from "./BookingDetailPlacement"

const bem = create(styles, "BookingDetail")

export const loadBooking = async () => {
  const { booking } = await getBookingDetail()
  if (!booking?.id) {
    return
  }
  const bookingResult = await getSalesorderById(booking?.id)
  const formResult = await getFormById(
    bookingResult?.id ?? "",
    "Main",
    bookingResult?.phase,
  )
  let formatedForm = []
  if (bookingResult?.phase !== "Draft") {
    formatedForm = formResult.map((form: xRMApiForm) => ({
      ...form,
      fields: form?.fields?.map(async (field) => {
        if (field.type === "file") {
          const defaultFiles = await getFilesById(booking?.id)
          const filteredDefaultFiles = defaultFiles?.filter(
            (f: xRMApiFile) => f.formControl === "",
          ) // Before filtered name of field
          return {
            ...field,
            files: filteredDefaultFiles,
          } as xRMApiField
        }
        return {
          ...field,
        }
      }),
    }))
  }
  let data = {
    activeStep: 0,
    steps: formatedForm,
  }
  if (bookingResult?.phase === "Draft" && formResult?.currentForm) {
    data = JSON.parse(formResult?.currentForm)
  }
  setBooking(bookingResult)
  setBookingDetails({
    ...data,
  })
}

export const loadPlacements = async () => {
  const { booking } = await getBookingDetail()
  const placements = await getPlacements(booking?.id)
  setPlacements(placements)
}

export const BookingDetail: FC<SharedPageProps> = ({
  header,
  page,
  pages,
  messages,
}) => {
  const dropdownRef = useRef<DropdownRef>(null)
  const [loading, setLoading] = useState(false)
  const locale = useLocale()
  const { permissions } = useAccount()
  const { loadingSalesorderDeletion, booking } = useBooking()
  const router = useRouter()
  const phase = booking?.phase
  const isActiveBooking =
    booking?.exhibition?.id === process.env.NEXT_PUBLIC_XRM_EVENT_ID

  const loadPermissions = async () => {
    const permission = await getPermission()
    mergeAccount({
      permissions: permission && permission?.length > 0 ? permission : [],
    })
  }

  const loadData = async () => {
    await loadBooking()
    await loadPlacements()
    setLoading(false)
  }

  const openBookingAsCopy = async () => {
    {
      setLoading(true)
      const formResultAsDraft = await getFormById(
        booking?.id ?? "",
        "Main",
        "Draft",
        true,
      )

      const data = JSON.parse(formResultAsDraft?.currentForm) as BookingDetails
      const steps = deepClone<xRMApiStep[]>(data.steps)

      resetSalesorder()
      setSalesorderSteps(steps)

      await router.push(redirectBookingForm?.uri as string).finally(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    loadPermissions()
  }, [])

  useEffect(() => {
    if (booking) {
      setLoading(true)
      loadData()
    }
  }, [booking?.id, locale])

  const redirectBookingForm = useMemo(
    () =>
      pages?.data?.nodes?.filter(
        (page) =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Booking Form Template",
      )?.[0],
    [pages, locale],
  )

  const hideCoexhibitor = booking?.exhibition?.hideCoexhibitor

  const adminPermission = permissions?.some(
    (el) => el.salesorderId === booking?.id && el.role === "Administrator",
  )
  if (loading || loadingSalesorderDeletion) {
    return <LinearProgress color="primary" />
  }
  return (
    <div className={bem()}>
      <div className={bem("header")}>
        <div className={bem("header__wrapper")}>
          <h1 className={bem("header__headline")}>{page?.data?.title}</h1>
          {phase && (
            <span className={bem("header__status", phase)}>
              {(messages.pages.booking.overview.table.phase.items as any)?.[
                phase
              ] ?? phase}
            </span>
          )}
        </div>
        <BookingDetailDropdown
          ref={dropdownRef}
          documentHeadline={page?.data?.title}
          header={header}
          isActiveBooking={isActiveBooking}
          messages={messages}
        />
      </div>
      <BookingDetailBanner
        handleButtonClick2={openBookingAsCopy}
        messages={messages}
        showContent2={phase !== "Draft"}
        showContent1={
          adminPermission &&
          phase !== "Draft" &&
          !hideCoexhibitor &&
          isActiveBooking
        }
      />
      {phase !== "Draft" && (
        <>
          <BookingDetailPlacement messages={messages} />
          <BookingDetailFileManagement messages={messages} />
        </>
      )}
      <div className={bem("info")}>
        {phase === "Draft" ? (
          <BookingDetailForm messages={messages} />
        ) : (
          <BookingDetailInfo messages={messages} />
        )}
      </div>
      {phase !== "Draft" && !hideCoexhibitor ? (
        <>
          <Divider />
          <BookingDetailCoExhibitorTable
            isActiveBooking={isActiveBooking}
            messages={messages}
          />
        </>
      ) : null}
    </div>
  )
}
