import React, { FC } from "react"

import { Link } from "src/components/common/link"
import { create } from "src/helpers/bem"

import { setNavigation } from "src/states/navigation"

import {
  MenuLocationEnum_menuItems_nodes_connectedNode,
  MenuLocationEnum_menuItems_nodes_childItems,
} from "src/types/wordpress/generated/MenuLocationEnum"

import { NavigationDropdown } from "./NavigationDropdown"

import styles from "./NavigationListItem.module.scss"

const bem = create(styles, "NavigationListItem")

export type NavigationListItemType = {
  __typename?: "MenuItem"
  isStatic?: boolean
  id: string
  label: string | null
  url?: string | null
  path?: string | null
  connectedNode?: MenuLocationEnum_menuItems_nodes_connectedNode | null
  childItems?: MenuLocationEnum_menuItems_nodes_childItems | null
  onClick?: () => void
}

export type NavigationListItemProps = {
  item: NavigationListItemType
}

export const NavigationListItem: FC<NavigationListItemProps> = ({ item }) => {
  const hasChilds = item?.childItems?.nodes && item.childItems.nodes.length > 0
  return (
    <li key={item?.id} className={bem()}>
      {item?.isStatic ? (
        <span
          className={bem("label")}
          onClick={() => {
            item?.onClick?.()
            setNavigation({ visible: false })
          }}
        >
          {item?.label}
        </span>
      ) : (
        <Link
          href={item?.connectedNode?.node?.uri ?? item?.url ?? "#"}
          target={getTarget(item)}
          classNames={{
            label: bem("label"),
            link: bem("link"),
          }}
          onClick={() => setNavigation({ visible: false })}
          onMouseEnter={
            hasChilds
              ? () =>
                  setNavigation({
                    visible: true,
                    title: item?.label ?? "",
                    items: item?.childItems,
                  })
              : () => setNavigation({ visible: true })
          }
        >
          {item?.label}
        </Link>
      )}
      {item?.childItems ? (
        <NavigationDropdown
          className={bem("dropdown")}
          nodes={item?.childItems}
          title={item?.label ?? ""}
        />
      ) : null}
    </li>
  )
}

const getTarget = (item: NavigationListItemType | null) => {
  if (item?.connectedNode?.node?.uri) {
    return "_self"
  }

  if (item?.url && !item?.url.includes(window.location.host)) {
    return "_blank"
  }

  return "_self"
}
