import xrmApi from "src/services/xrmApi"

import { xRMApiPurchaseorder, xRMApiPurchaseorderPermission } from "src/types/xRM"

const purchaseorderEndpoint = "/purchaseorder"
const purchaseorderPermissionEndpoint = "/purchaseorderpermission"

export const postPurchaseorder = async (data: xRMApiPurchaseorder) => {
  return xrmApi.post(purchaseorderEndpoint, data)
  .then(async (res) => {
    // API returns 409 conflict if products or prices have changed in the meantime.
    return { mustRefresh: res.status === 409 }
  })
  .catch((res) => {
    return { mustRefresh: res.response.data.statusCode === 409 }})
}

export const postPurchaseorderRefresh = async (data: xRMApiPurchaseorder) : Promise<xRMApiPurchaseorder> => {
  return xrmApi.post(`${purchaseorderEndpoint}/refresh`, data).then(async (res) => {
    return res?.data ?? null
  })
}

export const postPurchaseorderPermission = async (data: xRMApiPurchaseorderPermission) => {
  return xrmApi.post(purchaseorderPermissionEndpoint, data).then(async res => res?.data ?? null)
}

export const getPurchaseorderPermissions = async (): Promise<xRMApiPurchaseorderPermission[]> => {
  return xrmApi
    .get(`${purchaseorderPermissionEndpoint}?exhibitionId=${process.env.NEXT_PUBLIC_XRM_EVENT_ID}`)
    .then(res => res?.data ?? [])
}