import { useHookstate } from "@hookstate/core"

import { xRMApiOptionset } from "src/types/xRM"

import { createAspState } from "./createAspState"

export type Optionset = xRMApiOptionset

export type Optionsets = {
  languages: Optionset[]
  academicTitles: Optionset[]
  companyTypes: Optionset[]
}

export const defaultState = {
  languages: [],
  academicTitles: [],
  companyTypes: [],
} as Optionsets

const state = createAspState<Optionsets>(defaultState, "optionsets")

/**
 * Changes complete state
 * @param optionset name of the optionset
 * @param data account data
 */
export const setOptionset = (optionset: keyof Optionsets, data: Optionset[]) =>
  state[optionset].set(data)

/**
 * Changes a existing field
 * @param optionset name of the optionset
 * @param key key of the updated object in optionset
 * @param items the changed attributes of the optionset
 */
export const mergeOptionset = (
  optionset: keyof Optionsets,
  key: number,
  items: Optionset,
) => {
  const index = state[optionset].get().findIndex((e) => e.key === key)
  state[optionset].nested(index).merge({
    ...items,
  })
}

/**
 * Resets the complete Account state
 */
export const resetOptionsets = () => state.set(defaultState)

/**
 * Returns complete Account added to the state.
 * @returns the Account
 */
export const getOptionset = (optionset: keyof Optionsets) =>
  state[optionset].get()

/**
 * React hook to receive complete Account from the state.
 * @returns the Account
 */
export const useOptionsets = () => {
  return useHookstate(state).get()
}
