import { Alert } from "@mui/material"
import { SelectChangeEvent } from "@mui/material/Select"
import { useRouter } from "next/router"
import React, { FC, useEffect, useMemo } from "react"

import { ButtonProps } from "src/components/common/button"
import { Dialog } from "src/components/common/dialog"
import { Select } from "src/components/common/select"
import { BasicLayoutStyleProps } from "src/components/layouts/basic"

import { create } from "src/helpers/bem"

import { mergeAccount, useAccount } from "src/states/account"
import { useBookings } from "src/states/bookings"
import { useLocale } from "src/states/locale"
import { useCarts, mergeCarts } from "src/states/shop/carts"
import { mergeProducts } from "src/states/shop/products"

import { TranslationMessages } from "src/translations"

import { PagesProps } from "src/types/SharedPageProps"

import styles from "./ShopOverviewStandSelectionDialog.module.scss"

const bem = create(styles, "ShopOverviewStandSelectionDialog")


export type ShopOverviewStandSelectionDialogProps = {
  messages: TranslationMessages
  pages: PagesProps
  style: BasicLayoutStyleProps
}

export const ShopOverviewStandSelectionDialog: FC<
  ShopOverviewStandSelectionDialogProps
> = ({ messages, pages, style }) => {
  const router = useRouter()
  const locale = useLocale()
  const account = useAccount()
  const { bookings } = useBookings()
  const { salesorderDialogOpen, currentCartId: activeSalesorderId, salesorderDialogDisabled } = useCarts()
  const m = messages.pages.shop.overview.salesorderSelectionDialog

  useEffect(() => {
    mergeCarts({
      salesorderDialogOpen: salesorderDialogDisabled ? false : activeSalesorderId === "0" ? true : false,
    })
  }, [activeSalesorderId, salesorderDialogDisabled])

  const onCloseDialog = () => {
    mergeCarts({
      salesorderDialogOpen: false,
    })
  }

  const handleChangeSelect = (e: SelectChangeEvent<unknown>) => {
    const id = e?.target?.value as string
    mergeCarts({
      currentCartId: id,
    })
  }

  let formatedBookings = bookings
    .filter(booking => booking.phase === 'Placed')
    .map(booking => ({
      key: booking.id,
      value: booking.name
    }))

  if (formatedBookings?.length > 0) {
    formatedBookings = [
      {
        key: "0",
        value: `-- ${m.title} --`,
      },
      ...formatedBookings,
    ]
  }
  const redirectBookingForm = useMemo(
    () =>
      pages?.data?.nodes?.filter(
        (page) =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Booking Form Template",
      )?.[0],
    [pages, locale],
  )
  const redirectAccountForm = useMemo(
    () =>
      pages?.data?.nodes?.filter(
        (page) =>
          page?.language?.slug === locale &&
          page?.template?.templateName === "Account Overview Template",
      )?.[0],
    [pages, locale],
  )

  let firstRowButtons: ButtonProps[] = [
    {
      title: m.buttons.request,
      disabled: false,
      onClick: async () => {
        mergeCarts({ salesorderRequestDialogOpen: true})
      }
    },
    {
      title: m.buttons.authorize,
      disabled: account.webshop?.isAuthorized,
      onClick: async () => {
        mergeAccount({ webshop: { shouldActivateAccountWebshopTab: true } })
        await router.push(redirectAccountForm?.uri as string)
      }
    },
  ]
  let secondRowButtons: ButtonProps[] = [
    {
      title: m.buttons.jumpOver,
      variant: "text",
    },
    {
      title: m.buttons.disable,
      variant: "text",
      onClick: async () => {
        mergeCarts( {
          salesorderDialogDisabled: true,
        })
      }
    },
  ] as ButtonProps[]

  if (formatedBookings?.length === 0) {
    firstRowButtons = [
      {
        title: m.buttons.bookNow,
        onClick: async () => {
          mergeProducts({
            loading: true,
          })
          await router.push(redirectBookingForm?.uri as string)
          mergeProducts({
            loading: false,
          })
        },
      },
      ...firstRowButtons,
    ]
  }

  return (
    <Dialog
      closeOnClick
      firstRowButtons={firstRowButtons}
      open={useMemo(() => salesorderDialogOpen, [salesorderDialogOpen])}
      secondRowButtons={secondRowButtons}
      style={style}
      title={m.title}
      onClose={onCloseDialog}
    >
      <div className={bem()}>
        <Alert className={bem("info")} severity="info">
          <p>{m.info}</p>
        </Alert>
        <p className={bem("desc")}>{m.desc}</p>
        {formatedBookings?.length > 0 ? (
          <Select
            items={formatedBookings}
            title={m.input}
            value={activeSalesorderId}
            onChange={handleChangeSelect}
          />
        ) : (
          <p className={bem("helperText")}>{m.noData}</p>
        )}
      </div>
    </Dialog>
  )
}
