import xrmApi from "src/services/xrmApi"

import {
  xRMApiPostForm,
  xRMApiFormType,
  xRMApiSalesorderPhase,
} from "src/types/xRM"

export const getForm = async (formType: xRMApiFormType) => {
  return xrmApi
    .get(
      `/form?exhibitionId=${process.env.NEXT_PUBLIC_XRM_EVENT_ID}&formType=${formType}`,
    )
    .then(async (res) => {
      if (res?.data) {
        return res.data
      }
      return null
    })
}

export const getFormById = async (
  id: string,
  formType: xRMApiFormType,
  status?: xRMApiSalesorderPhase,
  isCopy?: boolean
) => {
  let endpoint = `/form/salesorder?exhibitionId=${process.env.NEXT_PUBLIC_XRM_EVENT_ID}&salesorderId=${id}&formType=${formType}`
  if (status === "Draft") {
    endpoint = `/form/draft?salesorderId=${id}&formType=${formType}${isCopy !== undefined ? "&isCopy=" + isCopy : ''}`
  }
  return xrmApi.get(endpoint).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}

export const postForm = async (
  data: xRMApiPostForm,
  status?: xRMApiSalesorderPhase,
) => {
  let endpoint = "/form"
  if (status === "Draft") {
    endpoint = "/form/draft"
  }
  return xrmApi.post(endpoint, data).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}
