import { useHookstate, SetPartialStateAction, none } from "@hookstate/core"

import { xRMApiPermission, xRMApiPermissionRole } from "src/types/xRM"

import { createAspState } from "./createAspState"

export interface BookingDetailPermissionsAddUser {
  open: boolean
  email: string
  emailErrorText?: string
  role: xRMApiPermissionRole
  roleErrorText?: string
}

export interface BookingDetailPermissions {
  open: boolean
  addUser: BookingDetailPermissionsAddUser
  permissions: xRMApiPermission[]
}

export const defaultState = {
  open: false,
  addUser: {
    open: false,
    email: "",
    role: "Administrator",
  },
  permissions: [],
} as BookingDetailPermissions

const state = createAspState<BookingDetailPermissions>(
  defaultState,
  "booking-detail-permissions",
)

/**
 * Merge the complete state
 * @param data the data for the state
 */
export const mergeBookingDetailPermissions = (
  data: SetPartialStateAction<BookingDetailPermissions>,
) => state.merge(data)

/**
 * Updates an existing permission
 * @param id the id of the user
 * @param items the changed attributes of the permission
 */
export const mergeBookingDetailPermission = (
  id: string,
  items: SetPartialStateAction<xRMApiPermission>,
) => {
  const permissionState = state.permissions
  if (permissionState?.length === 0) {
    return null
  }
  const permissionIndex = permissionState.get()?.findIndex((e) => e.id === id)
  permissionState.nested(permissionIndex).merge(items)
}

/**
 * Remove an existing permission
 * @param id the id of the user
 */
export const removeBookingDetailPermission = (id: string) => {
  const permissionState = state.permissions.get()
  if (permissionState?.length === 0) {
    return null
  }
  let permissionIndex = permissionState?.findIndex((e) => e.id === id)
  state.permissions.nested(permissionIndex).set(none)
}

/**
 * Set the complete state
 * @param data the data for the state
 */
export const setBookingDetailPermissions = (data: BookingDetailPermissions) =>
  state.set(data)

/**
 * Resets the complete state
 */
export const resetBookingDetailPermissions = () => state.set(defaultState)

/**
 * Returns the complete state.
 * @returns the BookingDetailPermissions
 */
export const getBookingDetailPermissions = () => state.get()

/**
 * React hook to receive complete BookingDetailPermissions from the state.
 * @returns the BookingDetailPermissions
 */
export const useBookingDetailPermissions = () => {
  return useHookstate(state).get()
}
