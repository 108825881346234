import moment from "moment"

// QUERIES
import { getAccount } from "src/queriesXRM/account"
import { countries } from "src/queriesXRM/countries"
import { getNomenclature } from "src/queriesXRM/nomenclature"
import { optionset } from "src/queriesXRM/optionset"
import { loadBookings, getCoExhibitorSalesordersById } from "src/queriesXRM/salesorder"
import { BookingCoExhibitor, setCoExhibitors } from "src/states/bookingDetail"

import { Locale } from "src/translations"

import { xRMApiSalesorder } from "src/types/xRM"

export const fetchXRM = async (locale: Locale) => {
  await countries()
  await getNomenclature()
  await optionset("mb_language")
  await optionset("mb_academic_titles_optionset")
  await optionset("mb_companytype_optionset")
  await getAccount()
  await loadBookings(locale)
}

export const fetchCoExhibitors = async (salesorderId: string) => {
  const res = (await getCoExhibitorSalesordersById(
    salesorderId,
  )) as xRMApiSalesorder[]
  const mappedResult = res?.map((el) => ({
    name: el?.clientAddressAccount1,
    phase: el?.phase,
    city: el?.clientAddressCity,
    country: el?.clientAddressCountry,
    createdOn: el?.createdOn ? moment(el?.createdOn).format("LLL") : null,
    id: el?.id,
    children: [
      {
        id: "company",
        items: [
          {
            name: "orderNumber",
            value: el?.orderNumber,
          },
          {
            name: "street",
            value: el?.clientAddressStreet1,
          },
          {
            name: "zip",
            value: el?.clientAddressZipCode,
          },
          {
            name: "website",
            value: el?.clientAddressWebsite,
          },
          {
            name: "phone",
            value: el?.clientContactPhone,
          },
        ],
      },
    ],
  }))
  setCoExhibitors(mappedResult as BookingCoExhibitor[])
}
