import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined"
import { SelectChangeEvent } from "@mui/material/Select"
import { useRouter } from "next/router"
import React, {
  FC,
  CSSProperties,
  useEffect,
  useState,
  ReactElement,
  useRef,
  useMemo,
} from "react"

import { loginRequest } from "src/auth/msalConfig"

import { Avatar } from "src/components/common/avatar"
import { Dropdown, DropdownRef } from "src/components/common/dropdown"
import { Icon } from "src/components/common/icon"
import { Link, LinkClassNames } from "src/components/common/link"
import { Select, SelectOption } from "src/components/common/select"

import { getTypoVariables } from "src/components/layouts/basic/BasicLayout.variables"

import { create } from "src/helpers/bem"
import { deepClone } from "src/helpers/deepClone"
import { resetStates } from "src/helpers/resetStates"
import { postPurchaseorderRefresh } from "src/queriesXRM/purchaseorder"
import { useAccount as useUser } from "src/states/account"
import { useBookings } from "src/states/bookings"
import { setLocale, useLocale } from "src/states/locale"
import { setNavigation } from "src/states/navigation"
import { addNotification } from "src/states/notifications"
import {
  useCarts,
  mergeCarts,
  useCart,
  CartProduct,
  setCart,
  getCartProductAmount,
  Carts,
} from "src/states/shop/carts"

import { LOCALES, Locale } from "src/translations"
import { SharedPageProps } from "src/types/SharedPageProps"

import { GET_HEADER } from "src/types/wordpress/generated/GET_HEADER"
import {
  GET_PAGE_page,
  GET_PAGE_page_featuredImage_node,
} from "src/types/wordpress/generated/GET_PAGE"

import styles from "./Header.module.scss"

const bem = create(styles, "Header")

export interface HeaderStyleProps extends CSSProperties {
  "--opacity": number
}

export type HeaderProps = {
  template?: string | null
  image?: GET_PAGE_page_featuredImage_node | null
} & GET_HEADER &
  Pick<GET_PAGE_page, "translations"> &
  Pick<SharedPageProps, "messages" | "typography">

export const Header: FC<HeaderProps> = ({
  template,
  image,
  translations,
  messages,
  typography,
  themeOptions,
}) => {
  const { company1 } = useUser()
  const dropdownRef = useRef<DropdownRef>(null)
  const m = messages?.components?.core?.header
  const [photoUrl, setPhotoUrl] = useState("")

  const bodyFont = getTypoVariables(
    "body",
    typography?.data.themeOptions?.typography?.body,
  )

  const router = useRouter()
  const locale = useLocale()

  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0])
  const isAuthenticated = useIsAuthenticated()
  const { bookings } = useBookings()
  const { currentCartId } = useCarts()
  const cart = useCart()

  const refreshCart = async () => {
    if (cart.products.length === 0) return
    mergeCarts({ loading: true })
    const refreshed = await postPurchaseorderRefresh({
      salesorderId: cart.orderId,
      parentSalesorderId: cart.id,
      exhibitionId: process.env.NEXT_PUBLIC_XRM_EVENT_ID,
      products: deepClone<CartProduct[]>(cart.products),
    })
    setCart(refreshed.products ?? [])
    mergeCarts({ loading: false })
    // Notification is handled via API Response. Elsewise there would be multiple notifications (succeeded, refreshed...)
  }

  const onStorageUpdate = ({ key, oldValue, newValue } : StorageEvent) => {
    if (key !== "asp-shop-carts" || !newValue || !oldValue || oldValue === newValue) return
    const [ oldCart, newCart ] = [ oldValue, newValue ].map(v => (JSON.parse(v) as Carts).carts.find(c => c.id === currentCartId)!)
    const hasCartReset = oldCart.revisionNo === -1
    const hasCartChanged = oldCart.revisionNo >= newCart.revisionNo
    if (hasCartChanged && !hasCartReset) return
    setCart(newCart.products, !hasCartReset)
  };

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);

  useEffect(() => {
    async function changeLocale() {
      const translation = translations?.find(
        (t) => t?.language?.slug === locale,
      )
      if (translation) {
        const query = router?.query
        delete query.locale
        delete query.slug
        await router.replace(
          {
            pathname: translation.uri!,
            query: router.query,
          },
          undefined,
          { shallow: false },
        )
      }
    }
    if (
      router?.pathname === "/[locale]/[...slug]" ||
      router?.pathname === "/"
    ) {
      changeLocale()
    }
  }, [locale, translations, router])

  const handleLogin = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        extraQueryParameters: { lang: locale },
      })
      .catch((e) => {
        addNotification({
          variant: "error",
        })
      })
  }

  const items: SelectOption[] = LOCALES.map((locale) => {
    return {
      key: locale,
      value: m?.languageSelect?.[locale],
    }
  })

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    if (event.target.value as Locale) {
      setLocale(event.target.value as Locale)
    }
  }

  const handleBookingChange = (event: SelectChangeEvent<unknown>) => {
    const id = event?.target?.value as string
    mergeCarts({
      currentCartId: id,
    })
  }

  const renderAvatar = (username?: string, size?: number) => {
    return <Avatar photoUrl={photoUrl} size={size} username={username} />
  }

  const renderAccountLink = (
    element: ReactElement,
    classNames?: LinkClassNames,
  ) => {
    let accountURL = "/en/account"
    switch (locale) {
      case "de":
        accountURL = "/de/konto"
        break
      default:
        accountURL = "/en/account"
        break
    }
    return (
      <Link
        {...classNames}
        classNames={classNames}
        href={accountURL}
        variant="primary"
        onClick={() => dropdownRef?.current?.close()}
      >
        {element}
      </Link>
    )
  }

  const handleLogout = async () => {
    await resetStates()
    instance
      .logoutRedirect({
        postLogoutRedirectUri: "/",
      })
      .catch((e) => {
        addNotification({
          variant: "error",
        })
      })
  }

  const formatedBookings = [
    {
      key: "0",
      value: `${m?.bookingSelect.default}`,
    },
    ...bookings
      .filter(booking => booking.phase === 'Placed')
      .map(booking => ({
        key: booking.id,
        value: booking.name
      })),
  ]
  const isShop =
    isAuthenticated &&
    (template === "Webshop Overview Template" ||
      template === "Webshop Product Detail Template")
  const isShopAndHasBooking =
    isAuthenticated && isShop && currentCartId && currentCartId !== "0"
  const productSum = useMemo(
    () =>
      cart.products && cart.products?.length > 0
        ? cart.products.reduce((n, cartProduct) => n + getCartProductAmount(cartProduct), 0)
        : 0,
    [cart.products],
  )
  if (!m) {
    return null
  }
  return (
    <header
      className={bem()}
      style={
        image?.sourceUrl
          ? {
              backgroundImage: `url(${image?.sourceUrl})`,
              "--opacity": 0,
            }
          : ({
              "--opacity": 0,
              minHeight: "auto",
            } as HeaderStyleProps)
      }
    >
      <div className={bem("content")}>
        {/* The main logo (top left) */}
        <div className={bem("col", "left")}>
          <img
            alt={themeOptions?.header?.logos?.main?.alt ?? ""}
            className={bem("logo__main")}
            src={themeOptions?.header?.logos?.main?.src ?? ""}
            onClick={() => router.push("/")}
          />
          {themeOptions?.header?.logos?.secondary?.desc && (
            <p className={bem("logo__description")}>
              {themeOptions.header.logos.secondary.desc}
            </p>
          )}
          {themeOptions?.header?.logos?.secondary?.src ||
          themeOptions?.header?.logos?.secondary?.alt ? (
            <img
              alt={themeOptions?.header?.logos?.secondary?.alt ?? ""}
              className={bem("logo__secondary")}
              src={themeOptions?.header?.logos?.secondary?.src ?? ""}
            />
          ) : null}
        </div>
        <div className={bem("col", "right")}>
          <div className={bem("col__content", "left")}>
            {/* The toggle to switch between bookings for shopping cart */}
            {isShop && (
              <div className={bem("toggle")}>
                <Select
                  items={formatedBookings}
                  messages={messages}
                  name="booking-switch"
                  value={currentCartId}
                  variant="filled"
                  classes={{
                    root: bem("toggle__select"),
                    icon: bem("toggle__icon", "booking"),
                    selectedItem: bem("toggle__selectedItem"),
                    item: bem("toggle__item"),
                  }}
                  onChange={handleBookingChange}
                />
              </div>
            )}
            {/* The cart button of shop */}
            {isShopAndHasBooking && (
              <div
                className={bem("cart")}
                onClick={async () => {
                  mergeCarts({
                    cartDialogOpen: true,
                  })
                  await refreshCart()
                }}
              >
                <Avatar icon={<LocalMallOutlinedIcon />} />
                <span className={bem("cart__badge")}>
                  {productSum > 99 ? "99+" : productSum}
                </span>
              </div>
            )}
          </div>
          <div className={bem("col__content", "right")}>
            {/* The toggle to switch between languages */}
            <div className={bem("toggle")}>
              <Select
                items={items}
                messages={messages}
                name="language-switch"
                value={locale?.split("-")?.[0]}
                variant="filled"
                classes={{
                  root: bem("toggle__select"),
                  icon: bem("toggle__icon"),
                  selectedItem: bem("toggle__selectedItem"),
                  item: bem("toggle__item"),
                }}
                onChange={handleLanguageChange}
              />
            </div>
            <div className={bem("avatar__language__wrapper")}>
              {!isAuthenticated ? (
                <div
                  className={bem("avatar", {
                    "is-unauthorized": !isAuthenticated,
                  })}
                  onClick={handleLogin}
                >
                  {renderAvatar()}
                  <div
                    className={bem("avatar__content")}
                    style={{
                      ...bodyFont,
                    }}
                  >
                    {m.avatar.noAccount}
                  </div>
                </div>
              ) : (
                <Dropdown
                  ref={dropdownRef}
                  button={renderAvatar(
                    account?.name && account?.name !== "unknown"
                      ? account.name
                      : undefined,
                  )}
                >
                  <div
                    className={bem("avatar__dropdown")}
                    style={{
                      ...bodyFont,
                    }}
                  >
                    <div className={bem("avatar__header")}>
                      <span className={bem("avatar__header__company")}>
                        {company1 ?? null}
                      </span>
                      <span
                        className={bem("avatar__header__signout")}
                        onClick={handleLogout}
                      >
                        {m.avatar.signout}
                      </span>
                    </div>
                    <div className={bem("avatar__user")}>
                      <span
                        className={bem("avatar__image")}
                        onClick={() => dropdownRef?.current?.close()}
                      >
                        {renderAccountLink(
                          renderAvatar(
                            account?.name && account?.name !== "unknown"
                              ? account.name
                              : undefined,
                            75,
                          ),
                          {
                            link: bem("avatar__link"),
                            label: bem("avatar__label"),
                          },
                        )}
                      </span>
                      <div className={bem("avatar__user__info")}>
                        <h3 className={bem("avatar__user__name")}>
                          {account?.name && account?.name !== "unknown"
                            ? account.name
                            : m.avatar.noName}
                        </h3>
                        <span className={bem("avatar__user__email")}>
                          {account?.idTokenClaims?.email as string}
                        </span>
                        {renderAccountLink(<>{m.avatar.viewAccount}</>, {
                          link: bem("avatar__user__link"),
                          label: bem("avatar__user__label"),
                        })}
                      </div>
                    </div>
                  </div>
                </Dropdown>
              )}
              <span
                className={bem("navigation__toggle")}
                data-cy="Navigation-Button"
                onClick={() => setNavigation({ visible: true })}
              >
                <Icon className={bem("navigation__toggle__icon")} name="bars" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
